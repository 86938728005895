import { Box, Icon, type StackProps, VStack } from '@chakra-ui/react'
import { type IconType } from 'react-icons'
import { Link as AppLink } from '@chakra-ui/next-js'

export type TNavBarItem = {
  id: string
  label: string
  path: string
  icon: IconType
}

type NavLineBarItemProps = {
  item: TNavBarItem
  isActive?: boolean
} & StackProps

export const NavLineBarItem = ({ item, isActive, ...props }: NavLineBarItemProps) => {
  return (
    <VStack
      id={item.id}
      key={item.path}
      as={AppLink}
      href={item.path}
      p={2}
      gap={1}
      _hover={{
        textDecoration: 'none'
      }}
      userSelect="none"
      {...(isActive
        ? {
            color: 'primary'
          }
        : {
            opacity: 0.7
          })}
      {...props}>
      <Icon fontSize="2xl" as={item.icon} />
      <Box fontSize="xs" position="relative">
        <Box fontWeight="semibold" visibility="hidden">
          {item.label}
        </Box>

        <Box
          position="absolute"
          left="0"
          right="0"
          top="0"
          textAlign="center"
          {...(isActive ? { fontWeight: 'semibold' } : {})}>
          {item.label}
        </Box>
      </Box>
    </VStack>
  )
}
