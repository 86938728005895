import { useCallback, useState } from 'react'

export const useDeviceCurrentPosition = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [position, setPosition] = useState<GeolocationPosition>()
  const [error, setError] = useState<GeolocationPositionError>()

  const getCurrentPosition = useCallback(() => {
    setIsLoading(true)

    return new Promise<GeolocationPosition>((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (currentPosition) => {
          setPosition(currentPosition)
          resolve(currentPosition)
        },
        (positionError) => {
          setError(positionError)
          reject(new Error(positionError.message))
        }
      )
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  return {
    isLoading,
    position,
    error,
    getCurrentPosition
  }
}
