import { useEffect, useState } from 'react'

const LIMIT_IS_WINDOW_TOP = 35 // px
const ANIMATION_DELAY = 250 // ms

export const useWindowOnTop = () => {
  /*
   * The word Animation in variables means showing or hiding some elements which relay on value isWindowOnTop.
   * When these elements show or hide, it triggers window scroll event and 'window.scrollY' value changes.
   *
   * Example: we have <TopNavPanel height="20px" />
   * 1. When window.scrollY > 150px, we set height="0px" ->
   * 2. window.scrollY becomes 130px (browser makes it automatically)
   * 3. our handler runs again and sees that window.scrollY < 150px and tries to show element, set height="20px"
   * 4. window.scrollY becomes 150px -> and we end up an infinity loop
   *
   * To avoid it we wait until animation of changing element's height ends.
   * Check if the scroll continues toward the same direction we don't change value isWindowOnTop,
   * otherwise calculate it and set dispatch(setIsWindowOnTop(isTop))
   * */

  const [isWindowOnTop, setIsWindowOnTop] = useState(true)
  useEffect(() => {
    let timerId: number

    let prevScrollY = 0
    let prevIsTop = true
    let prevIsScrollDown = false

    let waitingAnimation = false
    let checkAfterWaitingAnimation = false
    let initAfterWaitingAnimation = false

    const handler = () => {
      if (typeof window !== 'undefined') {
        if (waitingAnimation) {
          return
        }

        const isTop = window.scrollY <= LIMIT_IS_WINDOW_TOP
        const isScrollDown = prevScrollY < window.scrollY

        if (checkAfterWaitingAnimation) {
          if (initAfterWaitingAnimation) {
            prevScrollY = window.scrollY
            initAfterWaitingAnimation = false
            return
          }

          if (prevIsScrollDown === isScrollDown) {
            prevScrollY = window.scrollY
            return
          }
          checkAfterWaitingAnimation = false
        }

        if (prevIsTop === isTop) {
          prevScrollY = window.scrollY
          prevIsScrollDown = isScrollDown
          return
        }

        setIsWindowOnTop(isTop)

        waitingAnimation = true
        prevIsTop = isTop
        prevScrollY = window.scrollY
        prevIsScrollDown = isScrollDown

        clearTimeout(timerId)

        timerId = setTimeout(() => {
          waitingAnimation = false
          checkAfterWaitingAnimation = true
          initAfterWaitingAnimation = true
        }, ANIMATION_DELAY) as unknown as number
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handler)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handler)
      }
    }
  }, [])

  return { isWindowOnTop }
}
