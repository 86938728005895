import type { StackProps } from '@chakra-ui/react'
import { Heading, Text, VStack } from '@chakra-ui/react'
import type { PropsWithChildren, ReactElement } from 'react'
import { Children, Fragment } from 'react'

type BaseProps = Omit<StackProps, 'children'> & {
  title: string
  subTitle?: string
  itemContainerProps?: StackProps
}

type NavSectionWithChildrenProps = BaseProps & {
  children: StackProps['children']
}

type NavSectionWithoutChildrenProps<T> = BaseProps & {
  items: T[]
  itemRenderer: (item: T) => ReactElement | null
}

type NavSectionProps<T> = NavSectionWithChildrenProps | NavSectionWithoutChildrenProps<T>

const NavSectionBase = ({
  title,
  subTitle,
  itemContainerProps,
  children,
  ...props
}: PropsWithChildren<BaseProps>) => {
  const items = Children.toArray(children)

  return (
    <VStack className="nav-section" spacing={4} w="full" {...props}>
      <Heading variant="secondary" w="full">
        {title}
      </Heading>

      {!!subTitle && (
        <Text color="text.secondary" fontSize="sm" w="full">
          {subTitle}
        </Text>
      )}

      <VStack className="nav-section__items" gap={2} w="full" {...itemContainerProps}>
        {items?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key -- need it
          <Fragment key={index}>{item}</Fragment>
        ))}
      </VStack>

      {!items?.length && (
        <VStack justify="center" minH="10vh">
          <Text color="text.secondary" fontSize="sm">
            No items.
          </Text>
        </VStack>
      )}
    </VStack>
  )
}

const NavSectionWithChildren = ({ children, ...props }: NavSectionWithChildrenProps) => (
  <NavSectionBase {...props}>{children}</NavSectionBase>
)

const NavSectionWithoutChildren = <T,>({
  items,
  itemRenderer,
  ...props
}: NavSectionWithoutChildrenProps<T>) => (
  <NavSectionBase {...props}>
    {items?.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key -- need it
      <Fragment key={index}>{itemRenderer(item)}</Fragment>
    ))}
  </NavSectionBase>
)

export const NavSection = <T,>(_props: NavSectionProps<T>) => {
  // @ts-expect-error -- complex props
  const { items, itemRenderer, children, ...props } = _props

  if (children) {
    return <NavSectionWithChildren {...props}>{children}</NavSectionWithChildren>
  }

  return <NavSectionWithoutChildren<T> itemRenderer={itemRenderer} items={items} {...props} />
}

export default NavSection
