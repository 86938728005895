'use client'
import type { FC } from 'react'
import { motion } from 'framer-motion'
import { Heading, HStack, Icon, List, ListItem } from '@chakra-ui/react'
import type { IconType } from 'react-icons'
import { Link } from '../../common/Link'

interface NavbarItemsProps {
  isToggled: boolean
  onToggle: () => void
  items: NavbarItem[]
}

type NavbarItem = {
  label: string
  path: string
  id: string
  icon: IconType
}

const NavbarItems: FC<NavbarItemsProps> = ({ items, onToggle }) => {
  const navList = {
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.07
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  }

  const navItem = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    }
  }

  return (
    <List
      animate="visible"
      as={motion.ul}
      className="navList"
      exit="hidden"
      initial="hidden"
      spacing={10}
      variants={navList}
      w="full">
      {items.map((item) => (
        <ListItem as={motion.li} key={item.id} variants={navItem}>
          <Link display="flex" href={item.path}>
            <HStack color="text.default" spacing={6} w="full">
              <Icon aria-label={item.label} as={item.icon} boxSize={8} />
              <Heading cursor="pointer" onClick={onToggle} variant="secondary">
                {item.label}
              </Heading>
            </HStack>
          </Link>
        </ListItem>
      ))}
    </List>
  )
}

export default NavbarItems
