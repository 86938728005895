'use client'
import type { ReactElement, ReactNode } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { Box, Flex, HStack, Icon, Spinner, VStack } from '@chakra-ui/react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Link } from '@chakra-ui/next-js'

export type NavSectionItemProps = Omit<StackProps, 'title'> & {
  icon: ReactElement
  title: ReactNode
  subTitle?: ReactNode
  label?: ReactNode
  href?: string
  isLoading?: boolean
}

export const NavSectionItem = ({
  icon,
  title,
  subTitle,
  label,
  href,
  isLoading,
  ...props
}: NavSectionItemProps) => {
  return (
    <HStack
      align="center"
      justifyContent="space-between"
      py={2}
      w="100%"
      {...(href
        ? {
            as: Link,
            href,
            _hover: {
              textDecoration: 'none'
            }
          }
        : {})}
      {...props}>
      <HStack align="center" gap="4" justify="center">
        <Flex justifyContent="center">{icon}</Flex>

        <VStack alignItems="flex-start" spacing={0}>
          {!!label && (
            <Box color="text.secondary" fontSize="sm">
              {label}
            </Box>
          )}

          <Box fontSize="md">{title}</Box>

          {!!subTitle && (
            <Box color="text.secondary" fontSize="sm">
              {subTitle}
            </Box>
          )}
        </VStack>
      </HStack>

      <Box fontSize="2xl">
        {isLoading ? <Spinner h={4} w={4} /> : <ArrowIcon isRendered={!!href || !!props.onClick} />}
      </Box>
    </HStack>
  )
}

const ArrowIcon = ({ isRendered }: { isRendered: boolean }) => {
  return isRendered ? <Icon as={MdOutlineKeyboardArrowRight} boxSize={4} /> : null
}
