import { useReducer } from 'react'

export enum ReducerActionType {
  Set = 'set',
  Reset = 'reset'
}

export interface Action<T> {
  type: ReducerActionType
  payload: T
}

export const useSetReset = <T>(initialData: T) => {
  return useReducer((state: T, { type, payload }: Action<T>): T => {
    switch (type) {
      case ReducerActionType.Set: {
        return { ...state, ...payload }
      }
      case ReducerActionType.Reset: {
        return initialData
      }
      default:
        return state
    }
  }, initialData)
}
