'use client'
import type { FC } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import type { StackProps } from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  StackDivider,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { CgMenu } from 'react-icons/cg'
import { GrClose } from 'react-icons/gr'
import { BiHomeSmile, BiSearch, BiSolidUserAccount } from 'react-icons/bi'
import { LuChefHat } from 'react-icons/lu'
import { MdOutlineSell } from 'react-icons/md'
import { useWindowOnTop } from '../../hooks'
import { Link } from '../../common'
import NavbarItems from './NavbarItems'

type NavbarProps = StackProps

const Navbar: FC<NavbarProps> = ({ ...props }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const { isWindowOnTop } = useWindowOnTop()

  const items = [
    {
      label: 'Home',
      path: '/app',
      id: 'home',
      icon: BiHomeSmile
    },
    {
      label: 'Explore',
      path: '/app/explore',
      id: 'explore',
      icon: BiSearch
    },
    {
      label: "Chef's space",
      path: '/app/supplier',
      id: 'supplier',
      icon: LuChefHat
    },
    {
      label: 'My orders',
      path: '/app/orders',
      id: 'orders',
      icon: MdOutlineSell
    },
    {
      label: 'Account',
      path: '/account',
      id: 'profile',
      icon: BiSolidUserAccount
    }
  ]

  const navContainer = {
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: { velocity: 20 },
        duration: 0.3
      }
    },
    hidden: {
      x: -250,
      opacity: 0,
      transition: {
        x: { velocity: 20 },
        duration: 0.3
      }
    }
  }

  return (
    <>
      <HStack
        as="nav"
        backdropFilter="blur(12px)"
        justify="space-between"
        p={2}
        position="fixed"
        shadow={isWindowOnTop ? 'none' : 'xs'}
        spacing={0}
        transition="all 0.75s cubic-bezier(.33,1,.68,1) .3s"
        w="full"
        zIndex="modal"
        {...props}>
        <IconButton
          aria-label="open"
          icon={<Icon as={CgMenu} boxSize={6} color="primary" />}
          onClick={onToggle}
          size="sm"
          variant="iconButton"
        />
      </HStack>

      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader p={0}>
            <HStack justify="space-between" p={3} py={4} w="full">
              <IconButton
                aria-label="open"
                icon={<Icon as={GrClose} boxSize={6} color="primary" />}
                onClick={onToggle}
                size="sm"
                variant="iconButton"
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <AnimatePresence>
              <VStack
                animate={isOpen ? 'visible' : 'hidden'}
                as={motion.div}
                color="primary"
                exit="hidden"
                h={{ base: '100%' }}
                initial="hidden"
                justify="flex-start"
                py={12}
                variants={navContainer}
                w={{ base: '100%' }}>
                <NavbarItems isToggled={isOpen} {...{ onToggle, items }} />
              </VStack>
            </AnimatePresence>
          </DrawerBody>
          <DrawerFooter>
            <VStack gap={10} w="full" pb={2}>
              {/*<Logo />*/}
              <HStack divider={<StackDivider />} justify="center" spacing={4} w="full">
                <Link href="/privacy">
                  <Text fontSize="xs">Privacy</Text>
                </Link>
                <Link href="/terms">
                  <Text fontSize="xs">Terms</Text>
                </Link>
                <Link href="/cookies">
                  <Text fontSize="xs">Cookies</Text>
                </Link>
                <Link href="/faqs">
                  <Text fontSize="xs">Help</Text>
                </Link>
              </HStack>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Navbar
