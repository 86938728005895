'use client'
import type { FC, ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { NavLineBar } from './NavLineBar'

interface NavLayoutProps {
  children: ReactNode | ReactNode[]
}

export const NavLayout: FC<NavLayoutProps> = ({ children }) => {
  return (
    <>
      <Box as="main" pb={12}>
        {children}
      </Box>
      <NavLineBar />
    </>
  )
}
