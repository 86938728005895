import { BiHomeSmile, BiSearch, BiSolidUserAccount } from 'react-icons/bi'
import { LuChefHat } from 'react-icons/lu'
import { MdOutlineSell } from 'react-icons/md'
import { HStack } from '@chakra-ui/react'
import { usePathname } from 'next/navigation'
import { useCallback, useMemo } from 'react'
import { NavLineBarItem, type TNavBarItem } from './NavLineBarItem'

export const NavLineBar = () => {
  const pathname = usePathname()
  const isItemActive = useCallback(
    (item: TNavBarItem) => {
      return item.path === '/app' ? pathname === item.path : pathname.startsWith(item.path)
    },
    [pathname]
  )

  const prefix = 'navbar-'
  const items: TNavBarItem[] = useMemo(
    () => [
      {
        id: `${prefix}home`,
        label: 'Home',
        path: '/app',
        icon: BiHomeSmile
      },
      {
        id: `${prefix}explore`,
        label: 'Explore',
        path: '/app/explore',
        icon: BiSearch
      },
      {
        id: `${prefix}supplier`,
        label: 'Chef',
        path: '/app/supplier',
        icon: LuChefHat
      },
      {
        id: `${prefix}orders`,
        label: 'Orders',
        path: '/app/orders',
        icon: MdOutlineSell
      },
      {
        id: `${prefix}account`,
        label: 'Account',
        path: '/account',
        icon: BiSolidUserAccount
      }
    ],
    []
  )

  return (
    <HStack
      width="100%"
      zIndex={1}
      px={2}
      pb={6}
      justifyContent="space-between"
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      backgroundColor="background.default"
      borderTopWidth="1px">
      {items.map((item) => (
        <NavLineBarItem key={item.path} item={item} isActive={isItemActive(item)} />
      ))}
    </HStack>
  )
}
